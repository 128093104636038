import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class PageNotFound extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/*====================  404 page content ====================*/}
        <div>
          <div className="error-page-wrapper d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-9 m-auto text-center">
                  <div className="error-content-centered d-flex align-items-center justfy-content-center">
                    <div className="error-page-content-wrap">
                      <h2>404</h2>
                      <h3>Ops...Página não encontrada</h3>
                      <p>
                        Por algum motivo a página que você solicitou não foi
                        encontrada, iremos verificar o quanto antes se é algum
                        possivel erro nosso, fique a vontade para navegar para
                        os outros conteúdos do site.
                      </p>
                      <a
                        href="/"
                        className="ht-btn ht-btn--default ht-btn--default--dark-hover"
                      >
                        Voltar para Home
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*====================  End of 404 page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default PageNotFound;
