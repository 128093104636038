import React, { Component } from "react";

class Sidebar extends Component {
  render() {
    let data = [
      {
        sidebarListLink: "./servicos/automoveis/acessorios",
        sidebarListIcon: "flaticon-015-cart",
        sidebarListText: "Automotivo - Acessórios"
      },
      {
        sidebarListLink: "./servicos/automoveis/envelopamento",
        sidebarListIcon: "flaticon-010-tank-1",
        sidebarListText: "Automotivo - Envelopamento"
      },
      {
        sidebarListLink: "./servicos/automoveis/peliculas",
        sidebarListIcon: "flaticon-002-welding",
        sidebarListText: "Automotivo - Películas"
      },
      {
        sidebarListLink: "./servicos/arquitetura/peliculas",
        sidebarListIcon: "flaticon-002-welding",
        sidebarListText: "Arquitetura - Películas"
      },
      {
        sidebarListLink: "./servicos/aeronaves/peliculas",
        sidebarListIcon: "flaticon-002-welding",
        sidebarListText: "Aeronaves - Películas"
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <li key={i}>
          <a href={`${process.env.PUBLIC_URL}/${val.sidebarListLink}`}>
            {/* <i className={val.sidebarListIcon} /> */}
            {val.sidebarListText}
          </a>
        </li>
      );
    });

    return (
      <div>
        <div className="sidebar-wrapper">
          <div className="sidebar">
            <h3 className="sidebar-title">Nossos Serviços</h3>
            <ul className="sidebar-list">{Datalist}</ul>
          </div>
          {/* <div className="sidebar">
                        <h3 className="sidebar-title">Download brochure</h3>
                        <ul className="sidebar-list">
                            <li><a href="/"><i className="fa fa-file-pdf-o" />Brochures.PDF</a></li>
                            <li><a href="/"><i className="fa fa-file-word-o" />Brochures.DOC</a></li>
                        </ul>
                    </div> */}
        </div>
      </div>
    );
  }
}

export default Sidebar;
