import React, { Component } from "react";
import Swiper from "react-id-swiper";

class BrandLogoSlider extends Component {
  render() {
    const params = {
      slidesPerView: 4,
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },

      // Responsive breakpoints
      breakpoints: {
        1499: {
          slidesPerView: 4
        },

        991: {
          slidesPerView: 3
        },

        767: {
          slidesPerView: 3
        },

        575: {
          slidesPerView: 2
        }
      }
    };

    let data = [
      { img: "5.png", logoLink: "/" },
      { img: "6.png", logoLink: "/" },
      { img: "7.png", logoLink: "/" },
      { img: "8.png", logoLink: "/" },
      { img: "9.jpeg", logoLink: "/" },
      { img: "10.png", logoLink: "/" }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className="swiper-slide brand-logo-slider__single" key={i}>
          <div className="image text-center">
            {/* <a href={val.logoLink}> */}
            <img
              src={require(`../assets/img/brands/${val.img}`)}
              className="img-fluid"
              alt=""
              width="100px"
            />
            {/* </a> */}
          </div>
        </div>
      );
    });

    return (
      <div>
        {/*====================  brand logo area ====================*/}
        <div
          className={`brand-logo-slider-area section-space--inner--60 ${this.props.background}`}
        >
          <div className="container">
            <div className="row" style={{ marginBottom: 75 }}>
              <div className="col-lg-12">
                <h3>As melhores marcas do mercado estão aqui</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {/* brand logo slider */}
                <div className="brand-logo-slider__container-area">
                  <Swiper {...params}>{DataList}</Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of brand logo area  ====================*/}
      </div>
    );
  }
}

export default BrandLogoSlider;
