import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Sidebar from "./components/Sidebar";
import ServiceGallery from "./components/ServiceGallery";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

function importAll(r) {
  return r.keys().map(r);
}

const img_default = importAll(
  require.context(
    "../assets/img/service/aeronaves-peliculas",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const services = [
  {
    name: "Películas em Aeronaves",
    description: `Pouquissímos lugares na cidade de São Paulo tem a
    oportunidade e a experiência para trabalhar com
    aeronaves. Películas especiais e uma mão de obra
    totalmente treinada, capacitada e muito cuidadosa é
    o que é preciso quando falamos de aeronaves, afinal
    não são vidros comuns iguais aos que achamos por aí
    nos carros e janelas concordam?!
`,
    galleryKey: "peliculas_aereas"
  }
];

const ServiceDetails = ({ service }) => (
  <>
    {/* service gallery */}

    <div className="content section-space--top--30">
      <div className="row">
        <div className="col-12">
          <h2>{service.name}</h2>
          <p>{service.description}</p>
        </div>
      </div>
    </div>
    <ServiceGallery images={img_default} />
  </>
);

class ServiceDetailsLeftSidebarPelAir extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        {/* <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Linha Auto</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href={`${process.env.PUBLIC_URL}/servicos`}>
                        Serviços
                      </a>
                    </li>
                    <li>Aeronaves - Películas</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--120">
          {/*Service section start*/}
          <div className="service-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12 order-1 order-lg-2">
                  <div className="service-details">
                    {services.map(service => (
                      <ServiceDetails service={service} />
                    ))}
                  </div>
                </div>
                <div className="col-lg-4 col-12 order-2 order-lg-1">
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Brand logo */}
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebarPelAir;
