import React, { Component } from "react";
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Funfact from "../components/Funfact";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

class FilmSimulator extends Component {
  state = {
    simulatorImg: 0,
    colorName: "film0.png",
  };

  onColorChange = (colorId) => {
    this.setState({ simulatorImg: colorId, colorName: `film${colorId}.png` });
  };

  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        {/* <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Simulador de Películas (Insufilm)</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="./">Home</a>
                    </li>
                    <li>Simulador</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--120">
          {/*About section start*/}
          <div className="about-section section-space--inner--120">
            <div className="container">
              <div className="about-wrapper row">
                <h1>
                  <span>Simule o seu Insufilm</span>
                </h1>
                <p>
                  Aqui você consegue ter uma ideia de como irá ficar o seu carro
                  após a instalação da película de insufilm, lembrando que
                  trabalhamos com diversas marcas, cores e tipos de película,
                  então os resultados podem ter alguma variação em sua
                  tonalidade real e de acordo com o tipo do vidro em que é
                  instalado. As fotos/imagens são meramente ilustrativas para
                  vias de comparação.
                </p>
                <div className="col-sm-6 col-12 order-1 order-lg-2">
                  <div className="about-image about-image-1">
                    <img
                      src={require(`../assets/img/simulator/${this.state.colorName}`)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12 order-3 order-lg-1">
                  <div className="about-content about-content-1">
                    <h3 style={{ marginBottom: 30 }}>
                      <span>Clique sobre uma cor abaixo:</span>
                    </h3>
                    <ul className="simulator-list">
                      <li>
                        <p
                          style={{ cursor: "pointer" }}
                          onmouse
                          onClick={() => this.onColorChange(0)}
                        >
                          {this.state.simulatorImg === 0 && (
                            <span className="icon">
                              <i className="fa fa-check" />
                            </span>
                          )}
                          Sem Película
                        </p>
                      </li>
                      <li>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onColorChange(7)}
                        >
                          {this.state.simulatorImg === 7 && (
                            <span className="icon">
                              <i className="fa fa-check" />
                            </span>
                          )}
                          G5 - Preto 5% *
                        </p>
                      </li>
                      <li>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onColorChange(3)}
                        >
                          {this.state.simulatorImg === 3 && (
                            <span className="icon">
                              <i className="fa fa-check" />
                            </span>
                          )}
                          Preto 20% *
                        </p>
                      </li>
                      <li>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onColorChange(2)}
                        >
                          {this.state.simulatorImg === 2 && (
                            <span className="icon">
                              <i className="fa fa-check" />
                            </span>
                          )}
                          Preto 35% *
                        </p>
                      </li>
                      <li>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onColorChange(1)}
                        >
                          {this.state.simulatorImg === 1 && (
                            <span className="icon">
                              <i className="fa fa-check" />
                            </span>
                          )}
                          Preto 50% *
                        </p>
                      </li>
                      <li>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onColorChange(6)}
                        >
                          {this.state.simulatorImg === 6 && (
                            <span className="icon">
                              <i className="fa fa-check" />
                            </span>
                          )}
                          Verde Natural 5%
                        </p>
                      </li>
                      <li>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onColorChange(5)}
                        >
                          {this.state.simulatorImg === 5 && (
                            <span className="icon">
                              <i className="fa fa-check" />
                            </span>
                          )}
                          Semi Refletiva
                        </p>
                      </li>
                    </ul>
                  </div>
                  <p>
                    * As porcentagens indicam o quanto de luz externa entra
                    dentro do carro com a respectiva película instalada, por
                    exemplo "20%" indica que apenas 20% da luz externa irá
                    entrar no carro.
                  </p>
                </div>
              </div>
            </div>

            {/* Feature Icon */}
            <FeatureIcon background="grey-bg" />
          </div>
          {/*About section end*/}

          {/* Fun fact */}
          <Funfact />

          {/* Brand logo */}
          <BrandLogoSlider background="" />
        </div>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default FilmSimulator;
