import React, { Component } from "react";
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Funfact from "../components/Funfact";
import TestimonialSlider from "../components/TestimonialSlider";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";

import img_videoBg from "../assets/img/about/about1-hlfilm.jpg";
import img_about2 from "../assets/img/about/about2-hlfilm.jpg";
import img_about3 from "../assets/img/about/about3-hlfilm.jpg";
import img_about4 from "../assets/img/about/about-chopper.jpeg";

class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        {/* <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Sobre Nós</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Sobre Nós</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--120">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--120">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-image-two">
                    <img src={img_videoBg} alt="" />
                    <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="VEEA9Qg45jY"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3>Nós somos a HL Film & Som</h3>
                    <h1>São mais de 10 anos de experiência no mercado</h1>
                    <h4>
                      Nos da HL Film & Som nos comprometemos em trabalhar sempre
                      com honestidade e transparência nos serviços prestados e
                      com nossos clientes.
                    </h4>
                    <p>
                      Loja especializada em instalação de Películas de Controle
                      Solar nas linhas Automotiva, Decorativa, Arquitetura e
                      Segurança. Instalações de Som, Multimídias, Caixas,
                      Módulos, Falantes, Ultra Leds, Farol de milha, Engates e
                      muito mais. Estamos no mercado desde 2009, vindo de uma
                      crescente especialização em instalação de toda linha de
                      acessórios automotivos. Possuímos uma equipe muito bem
                      preparada para executar os variados tipos de serviços com
                      muito capricho e cuidado com seu carro. Agradecemos muito
                      nossos clientes pela preferência e nossos colaboradores
                      pela confiança.
                    </p>
                    <a
                      href="servicos"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Nossos Serviços
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Feature Icon */}
          <FeatureIcon background="grey-bg" />

          {/*About section start*/}
          <div className="about-section section-space--inner--120">
            <div className="container">
              <div className="about-wrapper row">
                <div className="col-sm-6 col-12 order-1 order-lg-2">
                  <div className="about-image about-image-1">
                    <img src={img_about2} alt="" />
                  </div>
                </div>
                <div className="col-sm-6 col-12 order-2 order-lg-3">
                  <div className="about-image about-image-2">
                    <img src={img_about3} alt="" />
                  </div>
                </div>
                <div className="col-sm-6 col-12 order-6 order-lg-7">
                  <div className="about-image about-image-2">
                    <img src={img_about4} alt="" />
                  </div>
                </div>
                <div className="col-lg-6 col-12 order-3 order-lg-1">
                  <div className="about-content about-content-1">
                    <h1>
                      <span>Excelência em todos os Serviços</span>
                    </h1>
                    <p>
                      Na HLFilm você irá contar com profissionais que entendem
                      do assunto, pessoas que irão te explicar e tirar todas as
                      suas dúvidas referente aos nossos serviços, e cuidar de
                      tudo para que tudo fique perfeito e na melhor qualidade
                      possível.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 order-5">
                  <div className="about-content about-content-2">
                    <p>
                      Nossos profissionais estão em constante treinamento e
                      eventos sempre atualizados com as tecnologias do mercado e
                      também na qualidade da realização dos serviços.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-12 order-6"
                  style={{ marginTop: 30 }}
                >
                  <div className="about-content about-content-1">
                    <h1>
                      <span>Aeronaves</span>
                    </h1>
                    <p>
                      Trabalhamos com profissionais qualificados para instalação
                      de Películas até mesmo em aeronaves.
                    </p>
                    <p>Consulte-nos e saiba mais!</p>
                    <a
                      href={`${process.env.PUBLIC_URL}/contato`}
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Entre em contato
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Fun fact */}
          <Funfact />

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          <TestimonialSlider />

          {/* Brand logo */}
          <BrandLogoSlider background="" />
        </div>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default About;
