import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import img_about from "../assets/img/about/about1-hlfilm.jpg";

class VideoCta extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div>
        {/*====================  video cta area ====================*/}
        <div className="video-cta-area section-space--inner--120">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="video-cta-content">
                  <h4 className="video-cta-content__small-title">Sobre Nós</h4>
                  <h3 className="video-cta-content__title">
                    A melhor opção quando o assunto é películas, som e
                    acessórios para veículos.
                  </h3>
                  <p className="video-cta-content__text">
                    {`Seja bem-vindo a HL FILM & SOM.
Somos uma empresa especializada em aplicação de películas de controle solar nas linhas Automotiva, Decorativa, Arquitetura e de Segurança.
Temos toda linha de acessórios automotivos, Som, Leds, Multimídias, Engates, entre outros.
Estamos há mais de 10 (dez) anos oferecendo o melhor serviço com muito empenho e profissionalismo, contando com profissionais qualificados e especializados.
﻿Trabalhamos com as melhores marcas de películas e equipamentos automotivo do mercado.
Nossos profissionais estão capacitados para realizar um ótimo serviço de maneira rápida e limpa.
﻿Consulte nossos serviços e solicite um orçamento.`}
                  </p>
                  <a
                    href={`${process.env.PUBLIC_URL}/contato`}
                    className="ht-btn ht-btn--round"
                  >
                    CONTATO
                  </a>
                </div>
              </div>

              <div className="col-lg-5 offset-lg-1 col-md-6">
                <div className="cta-video-image">
                  <div className="video-popup">
                    <ModalVideo
                      channel="youtube"
                      isOpen={this.state.isOpen}
                      videoId="VEEA9Qg45jY"
                      onClose={() => this.setState({ isOpen: false })}
                    />
                    <button onClick={this.openModal}>
                      <div className="cta-video-image__image">
                        <img src={img_about} className="img-fluid" alt="" />
                      </div>
                      <div className="cta-video-image__icon">
                        <i className="ion-ios-play" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of video cta area  ====================*/}
      </div>
    );
  }
}

export default VideoCta;
