import React, { Component } from "react";
import Swiper from "react-id-swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class ServiceGallery extends Component {
  render() {
    const { images } = this.props;
    console.log(images);

    /* service image gallery slider params*/
    const params = {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      centeredSlides: true,
      navigation: {
        nextEl: ".ht-swiper-button-next",
        prevEl: ".ht-swiper-button-prev"
      },
      renderPrevButton: () => (
        <div className="ht-swiper-button-prev ht-swiper-button-nav">
          <i className="ion-ios-arrow-left" />
        </div>
      ),
      renderNextButton: () => (
        <div className="ht-swiper-button-next ht-swiper-button-nav">
          <i className="ion-ios-arrow-forward" />
        </div>
      )
    };

    let ImageGalleryDataList =
      images &&
      images.map((val, i) => {
        return (
          <div
            className="swiper-slide service-gallery__single-slide"
            key={`swiper-slide-img-${i}`}
          >
            <div
              className="item"
              style={{ width: 500, height: 500, margin: "auto" }}
            >
              <LazyLoadImage
                alt={"Imagens de Serviços HLFilm"}
                effect="blur"
                src={val}
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: 500,
                  maxWidth: 500
                }}
                placeholderSrc={val}
              />
            </div>
          </div>
        );
      });

    console.log({ public: process.env.PUBLIC_URL });

    return (
      <div>
        <div className="service-gallery" style={{ marginTop: 40 }}>
          <Swiper
            {...params}
            style={{
              maxWidth: this.props.galleryKey !== "default" ? "500px" : "unset"
            }}
          >
            {ImageGalleryDataList}
          </Swiper>
        </div>
      </div>
    );
  }
}

export default ServiceGallery;
