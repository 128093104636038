import React, { Component } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import logo from "../assets/img/logo/logo-hlfilm.png";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);

    this.mobileMenuElement = React.createRef();

    this.whatsNumber = `5511947727896`;
    this.whatsMessage = `Olá, gostaria de mais informações sobre os serviços da HL Film, pode me ajudar por favor?`;
    this.whatsLink = `https://api.whatsapp.com/send?phone=${this.whatsNumber}&text=${this.whatsMessage}`;
  }

  handleWhatsClick = (number) => {
    const link = `https://api.whatsapp.com/send?phone=${number}&text=${this.state.whatsMessage}`;
    window.open(link, "_blank");
  };

  activeMobileMenu = () => {
    this.mobileMenuElement.current.toggleMobileMenu();
  };

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = 0);
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  header area ====================*/}
        <div
          className={`header-area header-sticky header-sticky--default ${
            this.state.scroll > this.state.top ? "is-sticky" : ""
          }`}
        >
          <div className="header-area__desktop header-area__desktop--default">
            {/*=======  header top bar  =======*/}
            <div className="header-top-bar">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    {/* top bar left */}
                    <div className="top-bar-left-wrapper">
                      <div className="social-links social-links--white-topbar d-inline-block">
                        <ul>
                          <li>
                            <a
                              href="//facebook.com/hlfilmsom"
                              target="_blank"
                              rel="noopener noreferrer"
                              alt="Facebook"
                            >
                              <i
                                className="zmdi zmdi-facebook"
                                style={{ fontSize: 30 }}
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="//instagram.com/hlfilmsom"
                              target="_blank"
                              rel="noopener noreferrer"
                              alt="Instagram"
                            >
                              <i
                                className="zmdi zmdi-instagram"
                                style={{ fontSize: 30 }}
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="//youtube.com/channel/UC-EPy2THMvDmjRXfy13DNsQ/videos"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Youtube"
                            >
                              <i
                                className="fa fa-youtube-play"
                                style={{ fontSize: 30 }}
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href={this.whatsLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              alt="WhatsApp"
                            >
                              <i
                                className="zmdi zmdi-whatsapp"
                                style={{ fontSize: 30, color: "lightgreen" }}
                              />
                            </a>
                          </li>
                          {/* <li><a href="//twitter.com"><i className="zmdi zmdi-twitter" /></a></li> */}
                          {/* <li><a href="//vimeo.com"><i className="zmdi zmdi-vimeo" /></a></li> */}
                          {/* <li><a href="//linkedin.com"><i className="zmdi zmdi-linkedin-box" /></a></li> */}
                          {/* <li><a href="//skype.com"><i className="zmdi zmdi-skype" /></a></li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    {/* top bar right */}
                    <div className="top-bar-right-wrapper">
                      <a
                        href={`${process.env.PUBLIC_URL}/contato`}
                        className="ht-btn ht-btn--default d-inline-block"
                      >
                        Entre em Contato
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header top bar  =======*/}
            {/*=======  header info area  =======*/}
            <div className="header-info-area">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="header-info-wrapper align-items-center">
                      {/* logo */}
                      <div className="logo">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                          <img
                            src={logo}
                            className="img-fluid logo-390"
                            alt="Logo"
                          />
                        </Link>
                      </div>
                      {/* header contact info */}
                      <div className="header-contact-info">
                        <div className="header-info-single-item">
                          <div className="header-info-single-item__icon">
                            <a
                              href={this.whatsLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="zmdi zmdi-smartphone-android" />
                            </a>
                          </div>
                          <div className="header-info-single-item__content">
                            <h6 className="header-info-single-item__title">
                              Telefones
                            </h6>
                            <p className="header-info-single-item__subtitle" 
                            style={{ marginLeft: 30}} >
                              (11) 2978-2236
                            </p>
                            <p className="header-info-single-item__subtitle">
                              <a
                                onClick={() =>
                                  this.handleWhatsClick(5511947727896)
                                }
                                href="#whatsapp"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  className="zmdi zmdi-whatsapp"
                                  style={{ fontSize: 30, color: "lightgreen" }}
                                />{" "}
                                (11) 94772-7896
                              </a>
                            </p>
                            <p className="header-info-single-item__subtitle">
                              <a
                                href="#whatsapp"
                                onClick={() =>
                                  this.handleWhatsClick(5511943390389)
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  className="zmdi zmdi-whatsapp"
                                  style={{ fontSize: 30, color: "lightgreen" }}
                                />{" "}
                                <span>(11) 94339-0389</span>
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="header-info-single-item">
                          <div className="header-info-single-item__icon">
                            <a
                              href="https://g.page/HL-Film-Som?share"
                              target="_blank"
                              rel="noopener noreferrer"
                              alt="Go to Google Maps"
                            >
                              <i className="zmdi zmdi-home" />
                            </a>
                          </div>
                          <div className="header-info-single-item__content">
                            <h6 className="header-info-single-item__title">
                              Endereço
                            </h6>
                            <p className="header-info-single-item__subtitle">
                              <a
                                href="https://g.page/HL-Film-Som?share"
                                target="_blank"
                                rel="noopener noreferrer"
                                alt="Go to Google Maps"
                              >
                                Rua Helena do Sacramento, 927
                              </a>
                            </p>
                            <p className="header-info-single-item__subtitle">
                              Mandaqui - SP
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* mobile menu */}
                      <div
                        className="mobile-navigation-icon"
                        id="mobile-menu-trigger"
                        onClick={this.activeMobileMenu}
                      >
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header info area =======*/}
            {/*=======  header navigation area  =======*/}
            <div className="header-navigation-area default-bg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/* header navigation */}
                    <div className="header-navigation header-navigation--header-default position-relative">
                      <div className="header-navigation__nav position-static">
                        <nav>
                          <ul>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/`}>
                                HOME
                              </Link>
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/sobre`}>
                                SOBRE NÓS
                              </Link>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link to={`${process.env.PUBLIC_URL}/servicos`}>
                                SERVIÇOS
                              </Link>
                              <ul className="submenu">
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/servicos/automoveis/acessorios`}
                                  >
                                    Automotivo - Acessórios
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/servicos/automoveis/envelopamento`}
                                  >
                                    Automotivo - Envelopamento
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/servicos/automoveis/peliculas`}
                                  >
                                    Automotivo - Películas
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/servicos/arquitetura/peliculas`}
                                  >
                                    Arquitetura - Películas
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/servicos/aeronaves/peliculas`}
                                  >
                                    Aeronaves - Películas
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link
                                to={`${process.env.PUBLIC_URL}/servicos/automoveis/peliculas/simulator`}
                              >
                                SIMULADOR
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`${process.env.PUBLIC_URL}/projetos-realizados`}
                              >
                                NOSSOS PROJETOS
                              </Link>
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/contato`}>
                                CONTATO
                              </Link>{" "}
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header navigation area =======*/}
          </div>
        </div>
        {/*====================  End of header area  ====================*/}

        {/* Mobile Menu */}
        <MobileMenu ref={this.mobileMenuElement} />
      </div>
    );
  }
}

export default NavBar;
