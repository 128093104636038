import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class Services extends Component {
  render() {
    let data = [
      {
        pageLink: "servicos/arquitetura/peliculas",
        img: "peliculas-residenciais.jpg",
        iconClass: "flaticon-002-welding",
        serviceTitle: "Linha Arquitetura",
        serviceSubtitle:
          "Nossos serviços de instalação de películas em ambientes residênciais, comerciais e projetos de arquitetura em geral."
      },
      {
        pageLink: "servicos/automoveis/acessorios",
        img: "peliculas-automotivas.jpg",
        iconClass: "flaticon-043-wheel",
        serviceTitle: "Linha Automotiva",
        serviceSubtitle:
          "Instalação de películas, peças, acessórios, som e sistemas elétricos (como luzes e lâmpadas LED) em automóveis."
      },
      {
        pageLink: "servicos/aeronaves/peliculas",
        img: "servico-aereo.jpeg",
        iconClass: "flaticon-020-planning",
        serviceTitle: "Linha Aérea",
        serviceSubtitle:
          "A mais nova especialidade da HL Film, instalação de películas em aeronaves particulares ou comerciais."
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-md-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                  <img
                    src={require(`../assets/img/service/tipo-servico/${val.img}`)}
                    className="img-fluid"
                    alt="Service Grid"
                    style={{ width: 370, height: 237, objectFit: "cover" }}
                  />
                </a>
              </div>
              {/* <div className="icon">
                <i className={val.iconClass} />
              </div> */}
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                  {val.serviceTitle}
                </a>
              </h3>
              <p className="subtitle">{val.serviceSubtitle}</p>
              <a
                href={`${process.env.PUBLIC_URL}/${val.pageLink}`}
                className="see-more-link"
              >
                {"Conheça +"}
              </a>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        {/* <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Nossos Serviços</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Serviços</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  service page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Service section start*/}
          <div className="service-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="service-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/*====================  End of service page content  ====================*/}

        {/* Brand logo */}
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Services;
