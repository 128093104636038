import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

function importAll(r) {
  return r.keys().map(r);
}

const multimidias = importAll(
  require.context(
    "../assets/img/service/acessorios/multimidias",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const modulos = importAll(
  require.context(
    "../assets/img/service/acessorios/modulos",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const leds = importAll(
  require.context(
    "../assets/img/service/acessorios/leds",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const sensores = importAll(
  require.context(
    "../assets/img/service/acessorios/sensores",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const automotivoEnvelopamento = importAll(
  require.context(
    "../assets/img/service/automotivo-envelopamento",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const arquiteturaDefault = importAll(
  require.context(
    "../assets/img/service/arquitetura-peliculas/default",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const automotivoPeliculas = importAll(
  require.context(
    "../assets/img/service/automotivo-peliculas/default",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const aereoDefault = importAll(
  require.context(
    "../assets/img/service/aeronaves-peliculas",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

class Projects extends Component {
  render() {
    const allImages = [
      ...aereoDefault,
      ...automotivoPeliculas,
      ...arquiteturaDefault,
      ...automotivoEnvelopamento,
      ...sensores,
      ...leds,
      ...modulos,
      ...multimidias
    ];
    console.log("allImages: ", { allImages });

    let Datalist = allImages.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                {/* <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}> */}
                <img
                  src={val}
                  className="img-fluid"
                  style={{ width: 353, height: 293 }}
                  alt=""
                />
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        {/* <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Projetos Realizados</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Projetos Realizados</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="project-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
              {/* <div className="row section-space--top--60">
                <div className="col">
                  <ul className="page-pagination">
                    <li>
                      <a href="/">
                        <i className="fa fa-angle-left" /> Prev
                      </a>
                    </li>
                    <li className="active">
                      <a href="/">01</a>
                    </li>
                    <li>
                      <a href="/">02</a>
                    </li>
                    <li>
                      <a href="/">03</a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-angle-right" /> Next
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project page content  ====================*/}

        {/* Brand logo */}
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Projects;
