import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.scss";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import Home from "./Home";
import About from "./pages/About";
import Services from "./service/Services";
import ServiceDetailsLeftSidebarAccAuto from "./service/ServiceDetailsLeftSidebarAccAuto";
import ServiceDetailsLeftSidebarEnvAuto from "./service/ServiceDetailsLeftSidebarEnvAuto";
import ServiceDetailsLeftSidebarPelAir from "./service/ServiceDetailsLeftSidebarPelAir";
import ServiceDetailsLeftSidebarPelArc from "./service/ServiceDetailsLeftSidebarPelArc";
import ServiceDetailsLeftSidebarPelAuto from "./service/ServiceDetailsLeftSidebarPelAuto";
import FilmSimulator from "./pages/FilmSimulator";
import Projects from "./project/Projects";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/404";
import NoMAtch from "./pages/404";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'AW-947087886'
}

TagManager.initialize(tagManagerArgs)

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route exact path={`${process.env.PUBLIC_URL}/index.html`} component={Home} />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/sobre`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/servicos`}
            component={Services}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/servicos/automoveis/acessorios`}
            component={ServiceDetailsLeftSidebarAccAuto}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/servicos/automoveis/envelopamento`}
            component={ServiceDetailsLeftSidebarEnvAuto}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/servicos/automoveis/peliculas`}
            component={ServiceDetailsLeftSidebarPelAuto}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/servicos/arquitetura/peliculas`}
            component={ServiceDetailsLeftSidebarPelArc}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/servicos/aeronaves/peliculas`}
            component={ServiceDetailsLeftSidebarPelAir}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/servicos/automoveis/peliculas/simulator`}
            component={FilmSimulator}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projetos-realizados`}
            component={Projects}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contato`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/404`}
            component={PageNotFound}
          />
          <Route component={NoMAtch} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.register();
