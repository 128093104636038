import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import img_PelComr from "../assets/img/service/tipo-servico/peliculas-comerciais.jpeg";
import img_PelResi from "../assets/img/service/tipo-servico/peliculas-residenciais.jpg";
import img_PelAuto from "../assets/img/service/tipo-servico/peliculas-automotivas.jpg";
import img_AceAuto from "../assets/img/service/tipo-servico/acessorios-automotivos.jpg";

class ServiceTabExample extends Component {
  render() {
    /* service tab menu */
    let serviceTabMenuData = [
      { iconName: "fa fa-building", tabMenuName: "Películas Comerciais" },
      {
        iconName: "fa fa-home",
        tabMenuName: "Películas Residênciais"
      },
      { iconName: "fa fa-car", tabMenuName: "Películas Automotivas" },
      { iconName: "fa fa-cogs", tabMenuName: "Acessórios para Autos" }
    ];

    let serviceTabMenuDatalist = serviceTabMenuData.map((val, i) => {
      return (
        <Tab key={i}>
          {/* {" "}
          <span className="icon">
            <i className={val.iconName} />
          </span>{" "} */}
          <span className="text">{val.tabMenuName}</span>
        </Tab>
      );
    });

    /* service tab content */

    let serviceTabContentData = [
      {
        bgUrl: img_PelComr,
        contentTitle: "Películas Comerciais",
        contentDesc:
          "Temos todos os tipos de películas para ambientes comerciais, seja fachada, divisórias de vidro, entre outros!",
        serviceLink: "servicos"
      },
      {
        bgUrl: img_PelResi,
        contentTitle: "Películas Residênciais",
        contentDesc:
          "Temos todos os tipos de películas para ambientes residênciais, seja portas, divisórias, janelas, entre outros!",
        serviceLink: "servicos"
      },
      {
        bgUrl: img_PelAuto,
        contentTitle: "Películas Automotivas",
        contentDesc:
          "Trabalhamos com produtos das melhores marcas para seu carro ter a melhor película, inclusive com peliculas térmicas.",
        serviceLink: "servicos"
      },
      {
        bgUrl: img_AceAuto,
        contentTitle: "Acessórios para Autos",
        contentDesc:
          "Temos diversos tipos de acessórios em nossa loja para seu carro ou moto, entre em contato e solicite um orçamento!",
        serviceLink: "servicos"
      }
    ];

    let serviceTabContentDatalist = serviceTabContentData.map((val, i) => {
      return (
        <TabPanel key={i}>
          <div
            className="service-tab__single-content-wrapper"
            style={{
              backgroundImage: `url(${val.bgUrl})`
            }}
          >
            <div className="service-tab__single-content">
              <h3 className="service-tab__title">{val.contentTitle}</h3>
              <p className="service-tab__text">{val.contentDesc}</p>
              <a
                href={`${process.env.PUBLIC_URL}/${val.serviceLink}`}
                className="see-more-link"
              >
                VEJA MAIS
              </a>
            </div>
          </div>
        </TabPanel>
      );
    });

    return (
      <div>
        {/*====================  service tab area ====================*/}
        <div className="service-tab-area section-space--inner--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area text-center">
                  <h2 className="section-title section-space--bottom--50">
                    Nossos Serviços <span className="title-icon" />
                  </h2>
                </div>
              </div>
              <div className="col-lg-12">
                {/* service tab wrapper */}

                <div className="service-tab-wrapper">
                  <Tabs>
                    <div className="row no-gutters">
                      <div className="col-md-4">
                        <TabList>
                          <div className="service-tab__link-wrapper">
                            {serviceTabMenuDatalist}
                          </div>
                        </TabList>
                      </div>

                      <div className="col-md-8">
                        {serviceTabContentDatalist}
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of service tab area  ====================*/}
      </div>
    );
  }
}

export default ServiceTabExample;
