import React, { Component } from "react";
import Swiper from "react-id-swiper";

import img_serviceAereo from "../assets/img/service/recents/servico-aereo.jpeg";
import img_serviceAuto from "../assets/img/service/recents/slider2-service1.jpg";
import img_serviceAcessorios from "../assets/img/service/recents/slider2-service2.jpg";
import img_serviceAereoPeliculas from "../assets/img/service/recents/slider2-service3.jpg";
import img_serviceAcessorios02 from "../assets/img/service/recents/slider2-service4.jpg";

class ServiceGridSlider extends Component {
  render() {
    const params = {
      slidesPerView: 3,
      loop: true,
      speed: 1000,
      watchSlidesVisibility: true,
      spaceBetween: 30,
      autoplay: {
        delay: 3000
      },
      // Responsive breakpoints
      breakpoints: {
        1499: {
          slidesPerView: 3
        },

        991: {
          slidesPerView: 2
        },

        767: {
          slidesPerView: 1
        },

        575: {
          slidesPerView: 1
        }
      }
    };
    let data = [
      {
        img: img_serviceAereo,
        serviceTitle: "Película para Aeronaves",
        serviceExcerpt:
          "A única de SP com profissionais prontos para aplicação de película profissional em helicópteros",
        serviceUrl: "servicos"
      },
      {
        img: img_serviceAuto,
        serviceTitle: "Película Automotiva",
        serviceExcerpt:
          "Aplicação de película automotiva profissional na Mercedes C 300 do Mc Kauan",
        serviceUrl: "servicos"
      },
      {
        img: img_serviceAcessorios,
        serviceTitle: "Acessórios Automotivos",
        serviceExcerpt:
          "Instalação de lâmpadas ultra led e película insulfilm profissional G5 e G20.",
        serviceUrl: "servicos"
      },
      {
        img: img_serviceAereoPeliculas,
        serviceTitle: "Películas Comerciais",
        serviceExcerpt:
          "Aplicação profissional de Película Jateada - decorativa - em ambiente comercial.",
        serviceUrl: "servicos"
      },
      {
        img: img_serviceAcessorios02,
        serviceTitle: "Acessórios Automotivos",
        serviceExcerpt:
          "Nesse Nissan Versa foi Trocado as Lâmpadas Amarelas por Nosso Kit Ultra Led de 6500 lumens.",
        serviceUrl: "servicos"
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className="swiper-slide" key={i}>
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                <a
                  href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}
                  style={{ width: 350, height: 350 }}
                >
                  <img src={`${val.img}`} className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                <a href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}>
                  {val.serviceTitle}
                </a>
              </h3>
              <p className="subtitle">{val.serviceExcerpt}</p>
              <a
                href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}
                className="see-more-link"
              >
                SAIBA MAIS
              </a>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/*====================  project grid slider area ====================*/}
        <div className="service-slider-title-area grey-bg section-space--inner--top--120 section-space--inner--bottom--285">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area text-center">
                  <h2 className="section-title mb-0">
                    Projetos Recentes <span className="title-icon" />
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-grid-slider-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-slider">
                  <div className="service-slider__container service-slider__container--style2">
                    <Swiper {...params}>{DataList}</Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of project grid slider area  ====================*/}
      </div>
    );
  }
}

export default ServiceGridSlider;
