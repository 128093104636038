import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Sidebar from "./components/Sidebar";
import ServiceGallery from "./components/ServiceGallery";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

function importAll(r) {
  return r.keys().map(r);
}

const multimidias = importAll(
  require.context(
    "../assets/img/service/acessorios/multimidias",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const modulos = importAll(
  require.context(
    "../assets/img/service/acessorios/modulos",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const leds = importAll(
  require.context(
    "../assets/img/service/acessorios/leds",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const sensores = importAll(
  require.context(
    "../assets/img/service/acessorios/sensores",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const defaultImages = importAll(
  require.context("../assets/img/service/detalhes", false, /\.(png|jpe?g|svg)$/)
);

const services = [
  {
    name: "Multimídias",
    description:
      "Trabalhamos com as melhores marcas do mercado com diversos tipos de multimidia desde a mais simples até a mais atual com TV, Radio, Bluetooth e integração com celulares. Fale conosco e solicite um orçamento.",
    imageType: "multimidia"
  },
  {
    name: "Módulos",
    description:
      "Temos os mais variados e atualizados módulos para seu carro. Módulos para subida de vidros, retrovisores entre outros.",
    imageType: "modulos"
  },
  {
    name: "LED's e Super LED's",
    description:
      "Solicite um orçamento conosco para trocar suas lâmpadas convencionais por lâmpadas LED, mude o visual, a segurança e a visibilidade.",
    imageType: "leds"
  },
  {
    name: "Sensores e Câmeras",
    description:
      "Seu carro não tem sensor de ré ou camera de ré? Temos os mais variados tipos para todos os carros, entre em contato.",
    imageType: "sensores"
  }
];

const ServiceDetails = ({ service }) => {
  const literals = {
    multimidia: <ServiceGallery images={multimidias} />,
    modulos: <ServiceGallery images={modulos} />,
    leds: <ServiceGallery images={leds} />,
    sensores: <ServiceGallery images={sensores} />
  };
  return (
    <>
      {/* service gallery */}

      <div className="content section-space--top--30">
        <div className="row">
          <div className="col-12">
            <h2>{service.name}</h2>
            <p>{service.description}</p>
          </div>
        </div>
      </div>
      {literals[service.imageType] || <ServiceGallery images={defaultImages} />}
    </>
  );
};

class ServiceDetailsLeftSidebarAccAuto extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        {/* <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Linha Auto</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href={`${process.env.PUBLIC_URL}/servicos`}>
                        Serviços
                      </a>
                    </li>
                    <li>Automotivo - Acessórios</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--120">
          {/*Service section start*/}
          <div className="service-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12 order-1 order-lg-2">
                  <div className="service-details">
                    {services.map((service, index) => (
                      <ServiceDetails
                        key={`${service.imageType}-${index}`}
                        service={service}
                      />
                    ))}
                  </div>
                </div>
                <div className="col-lg-4 col-12 order-2 order-lg-1">
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Brand logo */}
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebarAccAuto;
