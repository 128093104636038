import React, { Component } from "react";
import Swiper from "react-id-swiper";

class TestimonialSlider extends Component {
  render() {
    const params = {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      effect: "fade",
      autoplay: {
        delay: 3000
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      },
      renderPagenation: () => <div className="swiper-pagination"></div>
    };
    let data = [
      {
        testimonialImage:
          "https://lh5.googleusercontent.com/-KkBikwbBKik/AAAAAAAAAAI/AAAAAAAAAAA/9dXu5uqMRhQ/s40-c-rp-mo-br100/photo.jpg",
        testimonialName: "Jonathan Juliani",
        testimonialDesignation: "",
        testimonialText: "Melhor serviço da região ! Pessoal muito parceiro!"
      },
      {
        testimonialImage:
          "https://lh4.googleusercontent.com/-bYoRb7mZxWQ/AAAAAAAAAAI/AAAAAAAAAAA/DcdluWInmc0/s40-c-rp-mo-br100/photo.jpg",
        testimonialName: "Pedro Ivankovich",
        testimonialDesignation: "",
        testimonialText:
          "Loja organizada e pessoal disposto a explicar e tirar as duvidas dos clientes estive sabado na loja e tive um ótimo atendimento feito pelo lucas e hugo"
      },
      {
        testimonialImage:
          "https://lh4.googleusercontent.com/-RB4SIzfaGc8/AAAAAAAAAAI/AAAAAAAAAAA/CRdolK09IDI/s40-c-rp-mo-br100/photo.jpg",
        testimonialName: "pedro motor",
        testimonialDesignation: "CEO",
        testimonialText:
          "Ótimo atendimento. Pessoal da uma atenção, explica tudo que foi feito"
      },
      {
        testimonialImage:
          "https://lh5.googleusercontent.com/-9Ti9myDn224/AAAAAAAAAAI/AAAAAAAAAAA/4q9GOazyXTc/s40-c-rp-mo-br100/photo.jpg",
        testimonialName: "Flávio film",
        testimonialDesignation: "CEO",
        testimonialText:
          "Ótima loja,recomendo, profissionais experientes e grande variedade de produtos para todos os tipos de veículos (nacionais e importados)!!!Top"
      },
      {
        testimonialImage:
          "https://lh5.googleusercontent.com/-svPpICbbOAk/AAAAAAAAAAI/AAAAAAAAAAA/5MS9-LA9XOA/s40-c-rp-mo-br100/photo.jpg",
        testimonialName: "Felippe Zancarli",
        testimonialDesignation: "CEO",
        testimonialText:
          "Filmei meu carro semana passada. A qualidade do serviço do Hugo é impressionante. Acompanhei a instalação. Ele se atenta aos mínimos detalhes, super caprichoso, cuidadoso. Recomento e muito!"
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className="swiper-slide testimonial-slider__single-slide" key={i}>
          <div className="author">
            <div className="author__image">
              <img
                // src={`assets/img/testimonial/${val.testimonialImage}`}
                src={`${val.testimonialImage}`}
                alt=""
                width="60px"
              />
            </div>
            <div className="author__details">
              <h4 className="name">{val.testimonialName}</h4>
              {/* <div className="designation">{val.testimonialDesignation}</div> */}
            </div>
          </div>
          <div className="content">{val.testimonialText}</div>
        </div>
      );
    });

    return (
      <div>
        {/*====================  testimonial slider area ====================*/}
        <div
          className="testimonial-slider-area section-space--inner--120"
          style={{ backgroundColor: "#242424" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="testimonial-slider">
                  <div className="testimonial-slider__container-area">
                    <Swiper {...params}>{DataList}</Swiper>
                    <div className="swiper-pagination"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of testimonial slider area  ====================*/}
      </div>
    );
  }
}

export default TestimonialSlider;
