import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Sidebar from "./components/Sidebar";
import ServiceGallery from "./components/ServiceGallery";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

function importAll(r) {
  return r.keys().map(r);
}

const img_default = importAll(
  require.context(
    "../assets/img/service/arquitetura-peliculas/default",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const img_antivandalismo = importAll(
  require.context(
    "../assets/img/service/arquitetura-peliculas/antivandalismo",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const img_termicas = importAll(
  require.context(
    "../assets/img/service/arquitetura-peliculas/termicas",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const services = [
  {
    name: "Películas para Arquitetura",
    description: `Temos as mais diversas películas para vidros, janelas e divisórias.`,
    imageType: "default"
  },
  {
    name: "Películas para Anti-Vandalismo",
    description: ``,
    imageType: "antivandalismo"
  },
  {
    name: "Películas Térmicas",
    description: ``,
    imageType: "termicas"
  }
];

const ServiceDetails = ({ service }) => {
  const literals = {
    default: <ServiceGallery images={img_default} />,
    antivandalismo: <ServiceGallery images={img_antivandalismo} />,
    termicas: <ServiceGallery images={img_termicas} />
  };
  return (
    <>
      {/* service gallery */}

      <div className="content section-space--top--30">
        <div className="row">
          <div className="col-12">
            <h2>{service.name}</h2>
            <p>{service.description}</p>
          </div>
        </div>
      </div>
      {literals[service.imageType] || <ServiceGallery images={img_default} />}
    </>
  );
};

class ServiceDetailsLeftSidebarPelArc extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        {/* <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Linha Auto</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href={`${process.env.PUBLIC_URL}/servicos`}>
                        Serviços
                      </a>
                    </li>
                    <li>Arquitetura - Películas</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--120">
          {/*Service section start*/}
          <div className="service-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12 order-1 order-lg-2">
                  <div className="service-details">
                    {services.map(service => (
                      <ServiceDetails service={service} />
                    ))}
                  </div>
                </div>
                <div className="col-lg-4 col-12 order-2 order-lg-1">
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Brand logo */}
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebarPelArc;
