import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class Contact extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    responseMessage: "",
    loading: false,
  };

  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  clearState() {
    this.setState({
      name: "",
      email: "",
      message: "",
      responseMessage: "",
      loading: false,
    });
  }

  clearResponseMessage() {
    setTimeout(() => {
      this.setState({ responseMessage: "" });
    }, 3000);
  }

  handleSubmit = async () => {
    this.setState({ loading: true });
    console.log({ state: this.state });

    const { name, email, message } = this.state;

    if (!name || !email || !message) {
      console.log("send failed, empty fields");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);

    fetch("https://www.hlfilmsom.com.br/phpmailer/sendMail.php", {
      method: "POST",
      mode: "no-cors",
      body: formData,
    }).then(async (response) => {
      console.log({ response });
      if (response.status >= 200 && response.status < 300) {
        const responseMessage = await response.text();
        console.log({ responseMessage });
        this.setState({ responseMessage });
      } else {
        this.setState({
          responseMessage:
            "Não foi possivel enviar o email no momento, tente novamente mais tarde.",
        });
      }

      this.setState({ loading: false }, this.clearResponseMessage());
    });
  };

  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        {/* <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Fale com a Gente</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Contato</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  content page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Contact section start*/}
          <div className="conact-section">
            <div className="container">
              <div className="row section-space--bottom--50">
                <div className="col">
                  <div className="contact-map">
                    <iframe
                      title="Localização"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.3949969567884!2d-46.635306685037136!3d-23.48227806456168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef9e0bde61c5f%3A0x320f518a79f0d038!2sHL%20Insulfilm%20%7C%20Som%20%7C%20Multim%C3%ADdia%20%7C%20M%C3%B3dulos%20%7C%20Engates!5e0!3m2!1spt-BR!2sbr!4v1578514871006!5m2!1spt-BR!2sbr"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="contact-information">
                    <h3>Informações de Contato</h3>
                    <ul>
                      <li>
                        <span className="icon">
                          <i className="ion-android-map" />
                        </span>
                        <span className="text">
                          <span>
                            Rua Helena do Sacramento, 927 - Santana, São Paulo
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-telephone-outline" />
                        </span>
                        <span className="text">
                          <a href="tel:1129782236">(11) 2978 2236</a>
                          <a href="tel:11947727896">(11) 9 4772 7896</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-email-outline" />
                        </span>
                        <span className="text">
                          <a href="mailto:contato@hlfilmsom.com.br">
                            contato@hlfilmsom.com.br
                          </a>
                          {/* <a href="mailto:info@example.com">info@example.com</a> */}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12">
                  {this.state.loading ? (
                    <h3>Enviando sua mensagem... aguarde!</h3>
                  ) : (
                    <div className="contact-form">
                      {this.state.responseMessage && (
                        <h4
                          style={{
                            border: "2px solid #eeeeee",
                            padding: "10px 20px",
                            color: "#0f3288",
                          }}
                        >
                          {this.state.responseMessage}
                        </h4>
                      )}
                      <h3>Deixe sua mensagem</h3>
                      <form id="contact-form" onSubmit={this.handleSubmit}>
                        <div className="row row-10">
                          <div className="col-md-6 col-12 section-space--bottom--20">
                            <input
                              name="name"
                              type="text"
                              id="name"
                              placeholder="Seu nome:"
                              value={this.state.name}
                              onChange={(e) => {
                                e.persist();
                                this.handleChange(e);
                              }}
                            />
                          </div>
                          <div className="col-md-6 col-12 section-space--bottom--20">
                            <input
                              name="email"
                              type="email"
                              id="email"
                              placeholder="Seu email:"
                              value={this.state.email}
                              onChange={(e) => {
                                e.persist();
                                this.handleChange(e);
                              }}
                            />
                          </div>
                          <div className="col-12">
                            <textarea
                              name="message"
                              type="textarea"
                              id="message"
                              placeholder="Sua mensagem:"
                              value={this.state.message}
                              onChange={(e) => {
                                e.persist();
                                this.handleChange(e);
                              }}
                            />
                          </div>
                          <div className="col-12">
                            <button type="submit">Enviar</button>
                            <button
                              type="reset"
                              onClick={() => this.clearState()}
                              style={{ marginLeft: 20 }}
                            >
                              Limpar
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*Contact section end*/}
        </div>
        {/*====================  End of content page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Contact;
